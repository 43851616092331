body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'PlusJakartaSans-Regular';
    src: local('PlusJakartaSans-Regular'), url('./assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'PlusJakartaSans-SemiBold';
    src: local('PlusJakartaSans-SemiBold'), url('./assets/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: local('PlusJakartaSans-Bold'), url('./assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url('./assets/fonts/Inter_18pt-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: local('Inter-SemiBold'), url('./assets/fonts/Inter_18pt-SemiBold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter-Bold';
    src: local('Inter-Bold'), url('./assets/fonts/Inter_18pt-Bold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: local('Inter-Medium'), url('./assets/fonts/Inter_18pt-Medium.ttf') format('truetype');
    font-weight: normal;
}
